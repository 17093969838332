import styled, { keyframes } from 'styled-components';
import Logo from "../../static/logo_white.svg";

export const LoginContainer = styled.div`
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const BackgroundImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    border: 20px solid white;
  background-color: white;

`;

export const BackgroundImage = styled.img`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
`;

export const GraphicSide = styled.div`
  flex: 1;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  max-width: 500px;

  @media (max-width: 768px) {
    max-height: 300px;
  }
`;

export const LogoContainer = styled.img`
  width: 35%;
  height: 35%;
`;

export const FormSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 3;
  height: 100%;
  border-radius: 10px;
  padding-bottom: 20px;

  @media (min-width: 769px) {
    max-width: 350px;
  }

  @media (max-width: 768px) {
    max-height: 400px !important;
    height: 100%;
  }
`;

export const LoginForm = styled.form`
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  color: #111E12;

  @media (min-width: 769px) {
    max-height: 400px !important;
  }
`;

export const RegisterTitle = styled.h2`
  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
`;

export const InputGroup = styled.div`
  margin-bottom: 20px;
  corner-radius: 10px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 3px solid #111E12;
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  background: #111E12;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  
  :hover {
    background: #E9FD8C;
    color: #111E12;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const Checkbox = styled.input`
  margin-right: 10px;
`;

export const Label = styled.label`
  font-size: 16px;
  color: #111E12;
  margin: 0;
`;