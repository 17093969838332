import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NavBarRegister } from '..'
import {
    LoginContainer, GraphicSide, FormSide, LoginForm, InputGroup, Input, Button,
    BackgroundImageContainer, BackgroundImage, LogoContainer, RegisterTitle
} from "./Login.styled";
import { useAuth } from "../../hooks";
import { checkToken } from "../../repositories/utils";
import tennisCourt from "../../static/tennis_court.webp";
import Logo from "../../static/logo_white.svg";

export const Login = (props) => {
    const [email, setEmail] = useState("");
    const { login } = useAuth()
    const { t } = useTranslation();

    useEffect(() => {
        if (checkToken()) props.history.push('/home/logged');
    })

    const handleEmailChange = event => setEmail(event.target.value);

    const onClickHandler = () => {
        login(email)
        props.history.push('/check');
    }

    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (event) => {
      event.preventDefault();
      setIsSubmitted(true); // Update state to show confirmation message
      onClickHandler();
    };
  
    return(
        <>
            <NavBarRegister currentPage='login' />
            <BackgroundImageContainer>
                <BackgroundImage src={tennisCourt} alt="tennis court" className="background" />
            </BackgroundImageContainer>
            <LoginContainer>
                <GraphicSide>
                    <LogoContainer src={Logo} alt="logo" />
                </GraphicSide>
                <FormSide>
                    <LoginForm onSubmit={handleSubmit}>
                    <RegisterTitle>{t('login')}</RegisterTitle>
                        <InputGroup>
                            <Input type="email" placeholder="Email" required onChange={handleEmailChange} />
                        </InputGroup>
                        <InputGroup>
                            <Button type="submit">{t('login')}</Button>
                        </InputGroup>
                    </LoginForm>
                </FormSide>
            </LoginContainer>
        </>
    )
};
