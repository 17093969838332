import React from "react";
import { NavBarMain } from "..";
import { SettingsProvider } from "../../contexts";
import { observer } from "mobx-react-lite";
import _isEmpty from "lodash/isEmpty";
import _without from "lodash/without";
import { PricingSection } from "./PricingSection/PricingSection";
import { FooterSection } from '../Home/FooterSection/FooterSection';

import {
  EventsContainer,
  Subcontainer,
} from "./Pricing.styled";

export const Pricing = (props) => (
  <SettingsProvider type="live" >
      <PricingContainer {...props} />
  </SettingsProvider>
);


const PricingContainer = observer(({ history }) => {
  return (
    <>
      <EventsContainer>
        <NavBarMain currentPage="pricing" history={history} />
        <Subcontainer>
          <PricingSection />
        </Subcontainer>
        <FooterSection />
      </EventsContainer>
    </>
  );
})
