import styled, { css } from "styled-components";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';

const colors = {
  white: '#fff',
  darkGreen: '#1d2d1d',
  lightGreen: '#e9fd8c',
  darkText: '#111E12',
  hoverGreen: '#1BB78A',
  activeBlue: '#B8DCEF',
  logoutRed: '#ff6b66',
};

const fontStyles = css`
  font-family: 'Montserrat';
  letter-spacing: -0.04em;
`;

const responsiveFontSize = css`
  font-size: 18px;
  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

export const StyledNavbar = styled(Navbar)`
  top: 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // flex-wrap: wrap;
  max-width: ${props => ['arbs', 'pre'].includes(props.currentPage) ? '100%' : '1600px'};
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;

  @media (min-width: 991px) {
    .svg-inline--fa {
      display: none;
    }

    .fa-user {
      display: inline-block;
    }

    #basic-navbar-nav {
      padding-right: 10px;
    }
  }

  @media (min-width: 991px) and (max-width: 1260px) {
    #basic-navbar-nav .btn-lg {
      padding: 8px;
    }

    flex-wrap: wrap;
  }

  @media (max-width: 991px) {
    margin-top: -10px;
  }
`;


export const Header = styled.header`
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    width: 100%;
    padding-top: 0px;
  }

  .dropdown-toggle {
    font-size: 22px;
    margin-top: 8px;
  }
  `;

export const Logo = styled.h1`
  font-size: 2em;
  color: #fff;
  margin-right: 20px;
  display: flex;
  align-items: center;

  img {
    height: 60px;
  }

  @media (max-width: 991px) {
    img {
      height: 30px; // Adjust this value as needed
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  gap: 2px;

  @media (max-width: 991px) {
    gap: 0px;
    margin-left: -15px;
    flex-direction: column;
    align-items: flex-start;  
  }
`;

export const NavBarLink = styled.a`
  color: ${props => props.inverted ? colors.darkText : colors.white};
  ${fontStyles}
  ${responsiveFontSize}
  margin-bottom: 0;
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  background: ${props => props.active ? colors.darkGreen : 'transparent'};

  &:hover {
    color: ${colors.darkGreen};
    font-weight: bold;
    background: ${colors.lightGreen};
  }

  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

export const NavDrop = styled(NavDropdown)`
  ${fontStyles}
  ${responsiveFontSize}
  color: ${colors.white};
  margin-bottom: 0;

  .nav-link {
    padding: 0.6rem 1rem;
    color: ${props => props.inverted ? colors.darkText : colors.white};
  }

  .dropdown-toggle {
    font-weight: ${props => props.currentPage === 'pre' ? 'bold' : 'normal'};
    background: ${props => props.active ? colors.darkGreen : 'transparent'};
    border-radius: 5px;
  }

  .dropdown-menu {
    background-color: ${colors.darkGreen};
  }

  .dropdown-item {
    ${fontStyles}
    ${responsiveFontSize}
    color: ${colors.white} !important;
    font-weight: normal;

    &:hover {
      background-color: ${colors.lightGreen};
      color: ${colors.darkText} !important;
      font-weight: bold;
    }

    &.active, &:active {
      font-weight: bold;
      color: ${colors.darkText} !important;
      background-color: ${colors.activeBlue};

      &:hover {
        background-color: ${colors.lightGreen};
      }
    }
  }

  a:hover {
    background-color: ${colors.lightGreen} !important;
    color: ${colors.darkText} !important;
    border-radius: 5px;
    font-weight: bold;
  }
`;

export const NavDropUser = styled(NavDropdown)`
  ${fontStyles}
  ${responsiveFontSize}
  color: ${colors.white};
  margin-bottom: 0;

  > .dropdown-menu {
    right: 0 !important;
    left: unset;
    background-color: ${colors.darkGreen};
  }

  .nav-link {
    padding: 0.6rem 1rem;
    color: ${props => props.inverted ? colors.darkText : colors.white};
  }

  .dropdown-item {
    ${fontStyles}
    ${responsiveFontSize}
    color: ${colors.white} !important;
    font-weight: normal;

    &:hover {
      background-color: ${colors.lightGreen};
      color: ${colors.darkText} !important;
      font-weight: bold;
    }

    &:last-child:hover {
      background-color: ${colors.logoutRed};
      color: ${colors.white} !important;
    }
  }

  a:hover {
    color: ${colors.lightGreen} !important;
  }

  @media (max-width: 1000px) {
    display: none;
    margin: 0;

    .nav-link {
      padding: 0;
    }

    .dropdown-toggle {
      margin-top: 0;
    }
  }
`;

export const NavDropLanguage = styled(NavDropdown)`
  ${fontStyles}
  ${responsiveFontSize}
  color: ${colors.white};
  margin-bottom: 0;

  .nav-link {
    padding: 0.6rem 1rem;
    color: ${props => props.inverted ? colors.darkText : colors.white};
  }

  .dropdown-menu {
    background-color: ${colors.darkGreen};
  }

  .dropdown-item {
    color: ${colors.white} !important;
    font-weight: normal;
    font-size: 18px;

    &:hover {
      background-color: ${colors.lightGreen};
      color: ${colors.darkText} !important;
    }
  }

  .dropdown-toggle {
    padding: 1px 1rem;
  }
`;

export const NavLinkSignup = styled.a`
  color: ${colors.lightGreen};

  border: 1px solid ${colors.lightGreen};
  z-index: 1;

  :hover {
    background: ${colors.darkGreen};
  }

  @media (max-width: 991px) {
    display: none;
  }
`;

export const NavLinkLogin = styled.a`
  color: ${colors.darkText};

  background: ${colors.white};
  z-index: 1;

  :hover {
    color: ${colors.darkText};
    background: ${colors.lightGreen};
  }
`;

export const NavRightContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const NavMobile = styled.nav`
  display: none;
  gap: 2px;

  @media (max-width: 991px) {
    display: flex;
    gap: 0px;
    margin-left: -15px;
    flex-direction: column;
    align-items: flex-start; 
  }
`;

export const NavNotMobile = styled.nav`
  display: none;
  gap: 2px;

  @media (min-width: 992px) {
    display: flex;
    gap: 5px;
  }
`;