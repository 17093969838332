import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Logo from "../../static/logo_white.svg";

import "./Register.styled";
import {
  LoginContainer, GraphicSide, FormSide, LoginForm, InputGroup, Input, Button, CheckboxContainer, Checkbox, Label, BackgroundImage, LogoContainer, BackgroundImageContainer, RegisterTitle
} from "./Register.styled";
import { useUser } from "../../hooks";
import { checkToken } from "../../repositories/utils";
import { NavBarRegister } from '..'
import tennisCourt from "../../static/tennis_court.webp";

export const Register = (props) => {
  const { createUser } = useUser()
  const [formValues, setFormValues] = useState({ email: '', name: '', surname: '', terms: false });
  const { t } = useTranslation();

  useEffect(() => {
    if (checkToken()) props.history.push('/home/logged');
})

  const registerUser = async (values) => {
    await createUser(values)
    props.history.push('/check');
  }

  const handleInputChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    });
  };

  const handleCheckboxChange = (event) => {
    setFormValues({
      ...formValues,
      terms: event.target.checked
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formValues.terms && formValues.email && formValues.name && formValues.surname) {
      registerUser(formValues);
    } 
  };

  return (
    <>
      <NavBarRegister currentPage='register' />
      <BackgroundImageContainer>
        <BackgroundImage src={tennisCourt} alt="tennis court" className="background" />
      </BackgroundImageContainer>
      <LoginContainer>
        <GraphicSide>
          <LogoContainer src={Logo} alt="logo" />
        </GraphicSide>
        <FormSide>
          <LoginForm onSubmit={handleSubmit}>
            <RegisterTitle>{t('accountRegistration')}</RegisterTitle>
            <InputGroup>
              <Input type="email" name="email" placeholder="Email" required onChange={handleInputChange} />
            </InputGroup>
            <InputGroup>
              <Input type="text" name="name" placeholder={t('name')} required onChange={handleInputChange} />
            </InputGroup>
            <InputGroup>
              <Input type="text" name="surname" placeholder={t('surname')} required onChange={handleInputChange} />
            </InputGroup>
            <InputGroup>
              <CheckboxContainer>
                <Checkbox type="checkbox" name="terms" required onChange={handleCheckboxChange} />
                <Label>
                  <Trans
                    i18nKey="terms"
                    components={[<a target="_blank" rel="noreferrer" href="/terms" />]}
                  >
                  </Trans>
                </Label>
              </CheckboxContainer>
            </InputGroup>
            <InputGroup>
              <Button type="submit">{t('register')}</Button>
            </InputGroup>
          </LoginForm>
        </FormSide>
      </LoginContainer>
    </>
  );
};
