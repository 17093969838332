import styled from "styled-components";
import NavDropdown from 'react-bootstrap/NavDropdown';
import pattern from "../../static/home/pattern.svg";

// Styled components
export const Container = styled.div`
  display: flex;
  max-height: 1600px;
  background: radial-gradient(40% 55%, rgb(78, 137, 83) 0%, rgb(17, 30, 18) 100%)
  `;

export const Background = styled.div`
  flex: none;
  inset: 0;
  position: absolute;
  background-image: url("${pattern}");
  background-color: rgb(17, 30, 18);
  background-size: 470px 94px;
  opacity: 0.11;
  box-sizing: border-box;

  width: 100%;

  @media (max-width: 768px) {
    background-size: 94px 19px;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  max-width: 1600px;
  width: 100%;
  padding-bottom: 50px;
  margin: auto;
  flex-direction: column;

  @media (min-width: 1440px) {
    min-height: 80vh;
  }

  @media (max-width: 1440px) {
    width: 100%;
  }
`;

export const Header = styled.header`
  top: 0;
  width: 100%;
  max-width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    padding-top: 10px;
    margin-top: 10px;
  }
  `;

export const Main = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
  z-index: 1;
  flex-grow: 1;

  @media (max-width: 1400px) {
    flex-direction: column;
    height: 650px;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    height: 500px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    height: 400px;
  }
`;

export const Hero = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  width: 270px;

  @media (max-width: 1400px) {
    width: 100%;
    text-align: center;
  }
`;

export const HeroVideo = styled.div`
  padding: 0px 20px;
  max-width: 1100px;
  width: 100%;
  height: 100%;

  @media (max-width: 475px) {
    padding: 0px;
  }
`;

export const HeroHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1400px) {
    flex-direction: row;
    gap: 5px;
  }
`;

export const HeroHeading = styled.h1`
  color: #fff;
  font-size: 65px;
  line-height: 1;
  text-align: left;
  margin: 0;
  font-weight: 800;
  font-family: 'Inter';
  letter-spacing: -4.55px;
  max-width: 1000px;

  @media (min-width: 1000px) and (max-width: 1400px) {
    font-size: 6vw;
  }

  @media (min-width: 550px) and (max-width: 768px) {
    font-size: 10vw;
  }

  @media (max-width: 550px) {
    font-size: 11vw;
  }

  @media (max-width: 348px) {
    font-size: 40px; // Adjust this value as needed
  }

  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: appear 1s ease-in-out;
`;

export const HeroSubHeading = styled.h2`
  color: #8DB898;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  margin-top: 15px;
  letter-spacing: -1px;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  animation: appear 1s ease-in-out;
  
  @media (max-width: 1400px) {
    width: 100%;
    text-align: center;
  }
  `;

export const Button = styled.a`
  background-color: #E9FD8C; // Use the green color from the design for the button
  color: #2F5131;
  border: none;
  padding: 2px 30px;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s;
  border-radius: 10px;

  width: fit-content;
  display: inline-block;

  font-size: 1.9em;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.05em;

  border: 3px solid #E9FD8C;

  &:hover {
    text-decoration: none;
    border: 3px solid #007053;
  }

  @keyframes appearButton {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  animation: appearButton 1s ease-in-out;

  @media (max-width: 768px) {
    width: 270px;
  }
`;

export const NavDrop = styled(NavDropdown)`
  color: white;
  font-family: 'Montserrat';
  margin-bottom: 0;
  letter-spacing: -0.04em;
  font-size: 30px;
  z-index: 10;
  padding-top: 4px;

  @media (min-width: 768px) {
    padding-top: 8px;
  }

  a {
    color: ${props => props.inverted ? '#111E12' : 'white'};
    font-family: 'Montserrat';
    margin-bottom: 0;
    letter-spacing: -0.04em;
    font-size: 18px;
    font-weight: ${props => props.currentPage === 'arbspre' ? 'bold' : 'normal'};

    @media (max-width: 768px) {
      font-size: 14px;
    }

    :hover {
      color: #1BB78A;
    }
  }

  .nav-link {
    padding: 0.6rem 1rem;
  }

  .dropdown-toggle {
    font-size: 22px;
  }

  .dropdown-item {
    font-weight: normal;
    color: white !important;

    :hover {
      background-color: #1BB78A;
    }
  }
`;