import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { CircleButton, HelpTooltip } from "./HelpButton.styled";

export const HelpButton = ({ children, id, placement = "right", width = undefined }) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <HelpTooltip id={id} width={width}>
          {children}
        </HelpTooltip>
      }
    >
      {(props) => (
        <CircleButton {...props} size="sm" variant="secondary">
          ?
        </CircleButton>
      )}
    </OverlayTrigger>
  );
};
