import React from "react";
import { observer } from "mobx-react-lite";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useReport } from "../../hooks/useReport.hook";
import { useSettings } from "../../hooks/useSettings.hook";
import { CustomButton } from "./ReportModal.styled";

export const Step1 = observer(({ data, nextStep, setData }) => {
  const { reportArb } = useReport();
  if (!reportArb) return null;
  const {
    options: { bookieToLabel },
  } = useSettings();
  const { bet } = data;

  const selectBet = (bet) => {
    setData(data => ({ ...data, bet, startedOn: new Date() }));
    nextStep();
  };

  return (
    <>
      <Modal.Body>
        <p className="lead text-center">¿De qué bookie es la apuesta errónea?</p>
        <div className="d-flex justify-content-center">
          <div className="d-inline-block">
            <CustomButton
              variant={bet?.bookie === reportArb.bets[0].bookie ? "info" : "secondary"}
              block
              className="w-100"
              onClick={() => selectBet(reportArb.bets[0])}
            >
              {bookieToLabel[reportArb.bets[0].bookie]}
            </CustomButton>
            <CustomButton
              variant={bet?.bookie === reportArb.bets[1].bookie ? "info" : "secondary"}
              block
              className="mt-2 w-100"
              onClick={() => selectBet(reportArb.bets[1])}
            >
              {bookieToLabel[reportArb.bets[1].bookie]}
            </CustomButton>
          </div>
        </div>
      </Modal.Body>
    </>
  );
});
