import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import styled, { css } from "styled-components";

export const CircleButton = styled(Button)`
  margin-left: 5px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
  height: 25px;
  width: 25px;
  padding: 0;
`;

export const HelpTooltip = styled(Tooltip)`
  &.show {
    opacity: 1 !important;
  }
  & .tooltip-inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: left;
    ${({ width }) =>
      width &&
      css`
        min-width: ${width};
      `}
    & ul {
      margin: 0;
      padding-left: 20px;
    }
  }
`;