import styled, {css} from "styled-components";
import Badge from "react-bootstrap/Badge";

export const EventsContainer = styled.div`
  display: flex;
  // justify-content: center;
  flex-direction: column;
  background-color: #111E12;

  padding: 20px;
  min-height: 100vh;

  font-family: 'Montserrat';

  .text-muted {
    margin-bottom: 0px !important;
  }
`

export const Title = styled.h1`
  margin-bottom: 60px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: -0.04em;
  padding-bottom: 30px;
  width: 100%;
`;

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1600px;
  width: 100%;
  margin: auto;

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
`;

export const MainHeader = styled.div`
`

export const VideosHeader = styled.div`
    max-width: 100%;
`

export const HeaderPupsikContainer = styled.div`
  display: flex;
  margin-top: 20px; 
  flex-direction: row;
  justify-content: center;
  gap: 12px;
`

export const HeaderPupsikSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1px;
  align-items: end;
  background-color: #564e4e;
  padding: 5px;
  border-radius: 5px;
`

export const SportIcon = styled.img`
  padding-right: 10px;
  height: 20px;
  padding-right: 10px;
  filter: invert(100%) sepia(21%) saturate(0%) hue-rotate(4deg) brightness(108%) contrast(101%);
`
export const LeagueName = styled.a`
  color: gray;

  : hover {
    color: gray;
    text-decoration: none;
  }
`

export const LegendContainer = styled.div`
  display: flex;
  margin: 10px;
  align-items: center; 
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${props => props.selected ?  'white' : '#1d2d1d'};
  border: 1px solid #1d2d1d;
  color: ${props => props.selected ?  '#111E12' : 'white'};
  font-weight: ${props => props.selected ?  'bold' : 'normal'};

  :hover {
    background: #5556bb;
    color: white;
    font-weight: bold;
  }
`

export const TotalEventsContainer = styled.div`
  font-size: 25px;
  margin-bottom: 20px;
`

export const BookieListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 20px;
  width: 100%;

  gap: 15px;
`;

export const BookieBadge = styled(Badge)`
  padding: 0.6em;
  font-size: 100%;
  user-select: none;
  position: relative;
  flex-grow: 1;
  width: 100%;

  border: ${(props) => !props.checked ? "1px solid #1d2d1d" : "1px solid white"};
  background-color: ${(props) => props.checked ? (props.isReference ? "#350F87" : "#208064") : "rgb(254, 254, 244)"};
  color: ${(props) => !props.checked ? "#1d2d1d" : "white"};

  ${({hide}) => hide && css`display: none;`};
  ${({clickable}) => clickable !== false && css`
    cursor: pointer;
    input[type=checkbox] {
      cursor: pointer;
    }
    `
  };
  
  & button.close {
    top: 4px;
    right: 5px;
    margin-left: 5px;
    height: 14px;
    line-height: 14px;
  }
`;

export const ClonesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #82a880;
  // padding: 5px 0px 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  flex-grow: 1;
`;

export const MainBookieContainer = styled.div`
  background: #3d593c;
  padding: 10px 9px 0px 10px;
  border-radius: 5px;
  flex-grow: 1;

  ${props => props.nonClone && `margin-bottom: 5px;`}
`;

export const Clone2Container = styled.div`
  display: flex;
  gap: 5px;

  padding: 10px 9px 0px 10px;
`;

export const BookieSearchContainer = styled.div`
  margin-bottom: 15px;
  width: 200px;
`;