import styled from 'styled-components';
import tennisCourt from "../../static/tennis_court.webp";

export const LoginContainer = styled.div`
display: flex;
min-height: 100vh;
max-height: 100vh;
justify-content: center;
align-items: center;
background-color: #AAB0A9;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    border: 15px solid white;
  }
`;

export const BackgroundImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 20px solid white;
  background-color: white;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BackgroundImage = styled.img`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
`;

export const GraphicSide = styled.div`
  flex: 1;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  max-width: 500px;

  @media (max-width: 768px) {
    max-height: 330px;
    width: 100%;
    background-image: url(${tennisCourt});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 100%;
    border-radius: 20px;
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
    
  animation: appear 1s ease-in-out
`;

export const LogoContainer = styled.img`
  width: 35%;
  height: 35%;

  @media (max-width: 768px) {
    width: 20%;
    height: 20%;
  }
`;

export const FormSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 3;
  height: 100%;
  border-radius: 10px;
  padding-bottom: 20px;
  width: 100%;

  @media (min-width: 769px) {
    max-width: 350px;
  }

  @media (max-width: 768px) {
    max-height: 250px !important;
    height: 100%;
  }
`;

export const RegisterTitle = styled.h2`
  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
`;

export const LoginForm = styled.form`
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  color: #111E12;

  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  @media (min-width: 769px) {
    max-height: 400px !important;
  }
`;

export const InputGroup = styled.div`
  corner-radius: 10px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 3px solid #111E12;
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  background: #E9FD8C;
  color: #1D2D1D;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  height: 50px;
  font-size: 20px;
  font-weight: bold;

  :hover {
    background: #E9FD8C;
    color: #111E12;
  }
`;