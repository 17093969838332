import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import clsx from "clsx";
import { SendButton } from "./ReportModal.styled";

// Register Filepond plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

export const Step3 = ({ data, onSubmit, previousStep, setData }) => {
  const { comment, files, error } = data;
  const pondRef = useRef();
  const [pondError, setPondError] = useState(undefined);

  const setComment = (event) => {
    setData(data => ({...data, comment: event.target.value}))
  }

  // FilePond does not update update this function, it just uses the one from the first render
  const setFiles = (files) => {
    setData((data) => {
      const newData = { ...data, files };
      validate(newData); // remove or add errors
      return newData;
    });
  };

  const onSend = async () => {
    if (!validate()) return;
    await onSubmit(data);
  };

  const validate = ({error} = data) => {
    const pondInstance = pondRef.current;
    const files = pondInstance?.getFiles() || [];
    if (error?.filesRequired && files.length < error.filesRequired) {
      setPondError(`Es necesario al menos ${error.filesRequired} archivo(s) adjunto`);
      return false;
    }
    const totalSize = files.reduce((size, file) => size + file.fileSize, 0); // bytes
    // If total size if bigger than 10MB
    if (totalSize > 20 * 1024 * 1024) {
      setPondError("Los archivos total no deben de ocupar más de 20MB.");
      return false;
    }
    setPondError(undefined);
    return true;
  };

  return (
    <>
      <Modal.Body>
        <p className="lead text-center">Añade más información</p>
        <Form className={clsx({ invalid: pondError })}>
          <Form.Group className="mb-3" controlId="comment">
            <Form.Label style={{ fontWeight: "bold" }}>Descripción</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Añade una descripción del problema si lo crees necesario"
              value={comment}
              onChange={setComment}
            />
          </Form.Group>
          <Form.Group className="mb-0" controlId="files">
            <Form.Label style={{ fontWeight: "bold" }}>Archivos</Form.Label>
            <Form.Text className="m-0" style={{ color: "#758b77" }}>
              Puedes añadir un máximo de 4 archivos con un tamaño total de 10MB
            </Form.Text>
            <FilePond
              ref={pondRef}
              files={files}
              allowReorder={true}
              allowMultiple={true}
              onupdatefiles={setFiles}
              maxFiles={4}
              maxTotalFileSize="20MB"
              required={Boolean(error?.filesRequired)}
              labelIdle='Arrastra los ficheros o <span class="filepond--label-action">navega</span>'
            />
            <Form.Control.Feedback type="invalid">{pondError}</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button variant="secondary" onClick={previousStep}>
          Anterior
        </Button>
        <SendButton variant="primary" onClick={onSend}>
          Enviar reporte
        </SendButton>
      </Modal.Footer>
    </>
  );
};
