import React from "react";
import "./Home.styled";
import { useTranslation } from 'react-i18next';

import { FeaturesSection } from "./FeaturesSection/FeaturesSection";
import { FooterSection } from "./FooterSection/FooterSection";
import { NavBarMain } from "../NavBarMain/NavBarMain";
import { DotLottiePlayer } from '@dotlottie/react-player';
import explainerVideo from "../../static/home/explainer.lottie";
import { Helmet } from 'react-helmet-async';

import {
  Container,
  SubContainer,
  Header,
  Main,
  Hero,
  HeroHeadingContainer,
  HeroHeading,
  HeroSubHeading,
  Button,
  HeroVideo,
  Background,
} from './Home.styled.js';

export const Home = ({ history }) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>BetSmarter | {t('earn')}. {t('money')}. {t('safely')}.</title>
        <meta name="description" content={t('subHeading')} />
        <meta name="keywords" content="BetSmarter Bot, Arbitrage Bot, Betting Bot, Sports Betting, BetSmarter, arbitrage, betting, sports betting, bot, arbitraje deportivo, bot de arbitraje, bot de arbitraje deportivo, bot de arbitraje deportivo" />
        <meta name="author" content="BetSmarter" />
        <meta property="og:title" content={`BetSmarter | ${t('earn')}. ${t('money')}. ${t('safely')}.`} />
        <meta property="og:description" content={t('subHeading')} />
      </Helmet>
      <Container>
        <Background/>
        <SubContainer>
          <Header>
            <NavBarMain currentPage="globalHome" history={history} />
          </Header>
          <Main>
            <Hero>
              <HeroHeadingContainer>
                <HeroHeading>{t('earn')}.</HeroHeading>
                <HeroHeading style={{ color: '#E9FD8C', fontStyle: 'italic' }}>{t('money')}.</HeroHeading>
                <HeroHeading>{t('safely')}.</HeroHeading>
              </HeroHeadingContainer>
              <HeroSubHeading>{t('subHeading')}</HeroSubHeading>
              <Button href="/register">{t('heroButton')}</Button>
            </Hero>
            <HeroVideo>
            <DotLottiePlayer
                  src={explainerVideo}
                  autoplay
                  loop={false}
                  speed={0.9}
                  style={{ width: '100%' }}
                >
                </DotLottiePlayer>
            </HeroVideo>
          </Main>
        </SubContainer>
      </Container>
      <FeaturesSection />
      <FooterSection />
    </>
  );
};