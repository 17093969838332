import React from "react";
import { Helmet } from 'react-helmet-async';

import {
  NavbarLinksContainer,
  NavbarLinksList,
  StyledNavbar,
  LogInButton,
  HomeButton
} from "./NavBarRegister.styled";
import { checkToken } from "../../repositories/utils";
import { useTranslation } from 'react-i18next';

export const NavBarRegister = ({ color, currentPage }) => {
  const { t } = useTranslation();

  return (
    <StyledNavbar className="navbar navbar-expand" color={color}>
      {!checkToken() && (
        <>
              <Helmet>
                <meta name="theme-color" content="#FFFFFF" />
                <style>
                  {`
                    body {
                      background-color: white;
                    }
                  `}
                </style>
            </Helmet>
          <NavbarLinksContainer
            className=""
            id="navbarNav"
            justifyContent="flex-end"
          >
            <NavbarLinksList className="">
              <HomeButton className="btn btn-lg" href="/">{t('home')}</HomeButton>
              { currentPage !== "login" && <LogInButton className="btn btn-lg" href="/login">{t('login')}</LogInButton> }
              { currentPage !== "register" && <LogInButton className="btn btn-lg" href="/register">{t('signup')}</LogInButton> }
            </NavbarLinksList>
          </NavbarLinksContainer>
        </>
      )}
    </StyledNavbar>
  );
};
