import styled from "styled-components";


export const MinMaxContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
`

export const MinContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  padding: 10px;
  background: rgba(0,0,0,0.1);
  color: #ff8484;
`

export const MaxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  padding: 10px;
  background: rgba(0,0,0,0.1);
  color: #94E5AB;
`

export const Header = styled.div`
  font-size: 14px;
  color: white;
`

export const HistoryContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  background: rgba(0,0,0,0.1);
  gap: 3px;
`
export const OddsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`

export const OddsTimestamp = styled.div`
  font-size: 14px;
  color: white;
`

export const OddsValue = styled.div`
  font-size: 14px;
  font-weight: bold;
`