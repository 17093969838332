import React from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { SuccessCheckmark } from "./ReportModal.styled";

const CircleSpinner = () => (
  <Spinner animation="border" role="status">
    <span className="sr-only">Enviando...</span>
  </Spinner>
);

export const Submitting = ({ status }) =>
  status?.status == "running" ? (
    <Modal.Body>
      <div className="submitting">
        <p className="lead text-center">
          Enviando el reporte.
          <br />
          Esto puede tardar unos segundos, por favor espera...
        </p>
        <CircleSpinner />
      </div>
    </Modal.Body>
  ) : (
    <Modal.Body>
      <div className="submitting">
        { status?.status === "error"
          ? <div>
              <p className="lead text-center">Error enviando el reporte.</p>
              <p className="lead text-center">{`${status?.error}`}</p>
            </div>
          : <p className="lead text-center">Reporte enviado correctamente.</p>
        }
        { status?.status === 'success' && <SuccessCheckmark>
          <div className="check-icon">
            <span className="icon-line line-tip"></span>
            <span className="icon-line line-long"></span>
            <div className="icon-circle"></div>
            <div className="icon-fix"></div>
          </div>
        </SuccessCheckmark>
      }
      </div>
    </Modal.Body>
  );
