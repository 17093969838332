import styled from 'styled-components';

export const PricingSectionContainer = styled.section`
  display: flex;
  background-color: #1d2d1d;
  justify-content: center;
  padding: 20px;
  gap: 15px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  width: 100%;

  margin-top: 20px;

  @media (max-width: 1055px) {
    margin-top: 0px;
  }
`;

export const ModeContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 35px;
  width: 100%;

  @media (max-width: 1055px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: unset;
    width: 100%;
  }
`;

export const PricingContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  justify-content: center;
  gap: 0px; // adjust this to control the space between the items

  background-color: #1d2d1d; // Assuming this is the background color you want
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  align-self: center;

  color: #111E12;

  @media (max-width: 1055px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: unset;
    width: 100%;
  }
`;

export const PricingContainerFree = styled.div`
  display: flex;
  justify-content: center;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex-grow: 1;
  max-width: 300px;

  height: unset;
  align-self: stretch;

  color: #111E12;

  @media (max-width: 1055px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: unset;
    width: 100%;
    max-width: unset;
  }
`;

export const FeaturesContainer = styled.section`
  display: flex;
  justify-content: center;
  background-color: #111E12; // Assuming this is the background color you want
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex-direction: column;
  padding: 10px 20px;
  gap: 10px;

  color: white;

  @media (max-width: 1055px) {
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;

    border-radius: 10px 10px 0px 0px;
    padding: 10px 5px 20px 5px;
  }
`;

export const FeaturesContainerFree = styled.section`
  display: flex;
  justify-content: center;
  background-color: #111E12; // Assuming this is the background color you want
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex-direction: column;
  padding: 10px 20px;
  gap: 10px;

  color: white;
  width: 100%;

  @media (max-width: 1055px) {
    flex-direction: column;
    align-items: center;
    height: auto;

    border-radius: 10px 10px 0px 0px;
    padding: 10px 5px 20px 5px;
  }
`;

export const FeaturesTitle = styled.h2`
  font-size: 32px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0px;
  text-align: center;
`;

export const FeaturesListContainer = styled.section`
  display: flex;
  flex-direction: row;
  padding: 20px 20px 0px 0px;

  @media (max-width: 1055px) {
    paddng: 20px 0px 0px 0px;
    width: 100%;
  }
`;

export const FeaturesListContainerFree = styled.section`
  display: flex;
  flex-direction: row;
  padding: 20px 20px 0px 0px;

  @media (max-width: 1055px) {
    paddng: 20px 0px 0px 0px;
    width: 100%;
  }
`;

export const PlansContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  justify-content: center;
  color: #111E12;
  gap: 5px; // adjust this to control the space between the items

  @media (max-width: 1055px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  // @media (min-width: 728px) and (max-width: 1455px)  {
  //   display: grid;
  //   grid-template-columns: repeat(2, 2fr);
  //   gap: 5px; // adjust this to control the space between the items
  //   height: unset;
  // }
`;

export const Title = styled.h2`
    color: white;
    font-size: 44px;
    line-height: 1;
    margin: 0;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: -0.08em;
    margin-bottom: 0px;
    text-align: center;

    @media (max-width: 358px) {
      font-size: 34px;
    }
`;

export const Subtitle = styled.h2`
    color: #111E12;
    font-size: 34px;
    line-height: 1;
    margin: 0;
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: -0.08em;
    margin-bottom: 40px;
    text-align: center;
`;

export const PricingCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 300px; // or flex: 1 to distribute space evenly
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #111E12;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 35px;

  &:nth-child(2) { // This targets the "Popular" plan
    background-color: #B8DCEF; // The highlighted color for the popular plan
    color: #111E12;
  }

  &:nth-child(4) { // This targets the "Popular" plan
    background-color: #E9FD8C; // The highlighted color for the popular plan
    color: #111E12;
    min-height: 190px;
  }

  @media (max-width: 3455px) {
    height: 160px;
    width: 100%;
    gap: 20px;
    min-height: 190px;
  }
`;

export const PlanTitle = styled.h3`
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0px;
`;

export const PlanDays = styled.h3`
  text-align: center;
  font-family: 'Montserrat';
  margin-top: -37px;
  font-size: 20px;

  @media (max-width: 3455px) {
    margin-top: -20px;
  }
`;

export const PlanSale = styled.div`
  text-align: center;
  font-family: 'Montserrat';
  margin-top: -37px;
  font-size: 20px;

  background-color: red;
  font-weight: bold;
  border-radius: 5px;

  @media (max-width: 3455px) {
    margin-top: -20px;
  }
`;

export const PlanPrice = styled.div`
  text-align: center;
  font-size: 34px;

  font-family: 'Montserrat';
  margin-top: -20px;
`;

export const PlanPricePerDay = styled.div`
  text-align: center;
  font-size: 20px;

  font-family: 'Montserrat';
  font-weight: 300;
  margin-top: -20px;
`;

export const FeatureList = styled.ul`
  list-style: none;


  @media (max-width: 728px) {
    padding-left: 20px;
  }
`;

export const FeatureItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;

  font-family: 'Montserrat';
  font-weight: normal;
`;

export const ChoosePlanButton = styled.a`
    width: 100%;
    padding: 5px;
    border: none;
    border-radius: 10px;
    background-color: #E9FD8C; // Use the green color from the design for the button
    color: #2F5131;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 3px solid #E9FD8C;

    font-size: 1.7em;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.05em;
    align-self: center;

    &:hover {
      color: #007053;
      text-decoration: none;
      border: 3px solid #007053;
    }
  
    @media (max-width: 728px) {
      padding: 10px;
    }

    @media (max-width: 455px) {
      width: 200px;
    }
  
`;

export const CheckIcon = styled.img`
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;

  filter:  invert(100%);
`;
