import React from "react";
import { ResponsiveContainer, LineChart, XAxis, YAxis, CartesianGrid, Line } from 'recharts';
import { HistoryContainer, MinMaxContainer, MinContainer, MaxContainer, Header, OddsContainer, OddsTimestamp, OddsValue } from './HistoryTooltip.styled';

const getMinMax = (data) => {
    const odds = data.map(d => d.odds);

    const min = Math.floor(Math.min(...odds) * 10) / 10;
    const max = Math.ceil(Math.max(...odds) * 10) / 10;

    return [min, max];
  };

const getMinMaxWithDate = (data) => {
    const odds = data.map(d => d.odds);

    const min = data.find(d => d.odds === Math.min(...odds));
    const max = data.find(d => d.odds === Math.max(...odds));

    return [min, max].map(d => ({ timestamp: new Date(d.timestamp || d.updatedOn).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }), odds: d.odds }));
};

const calculatePercentageChange = (currentOdds, previousOdds) => {
    if (!previousOdds) {
        return 0;
    }
  const percentageChange = ((currentOdds - previousOdds) / previousOdds) * 100;
  return percentageChange > 0 ? `+${percentageChange.toFixed(2)}` : percentageChange.toFixed(2);
};


export const HistoryTooltip = ({ betHistory }) => {
    const OddsValueContainer = ({ odds, idx }) => {
        const percentageChange = idx < betHistory.length - 1 ? calculatePercentageChange(odds.odds, betHistory[idx + 1].odds) : undefined;
        const color = idx === 0 || idx === betHistory.length - 1 ? (idx === 0 ? '#E9DF8C' : '#B8DCEF') : (percentageChange > 0 ? '#94E5AB' : '#ff8484');

        return (
            <OddsValue style={{ color: color }}>
                {percentageChange && <span>{` (${percentageChange}%)`}</span>}
                {' '}{odds.odds.toFixed(2)}
            </OddsValue>
        )
    }

    return (<>
    <ResponsiveContainer width="100%" height={200} style={{ marginLeft: '-20px' }}>
      <LineChart data={betHistory.map(odds => ({ timestamp: new Date(odds.timestamp).toLocaleString(), odds: odds.odds })).toReversed()}>
        <XAxis stroke="white" axisLine={false} tickLine={false} tickMargin={1} tick={false}/>
        <YAxis stroke="white" domain={getMinMax(betHistory)} scale="linear" tickCount={5} minTickGap={1} axisLine={false} tickLine={false} tickFormatter={tick => tick.toFixed(2)}/>
        <CartesianGrid strokeDasharray="3 0" vertical={false} stroke="rgba(255, 255, 255, 0.1)" />
        <Line type="monotone" dataKey="odds" stroke="#B8DCEF" strokeWidth={2}  activeDot={{ r: 2 }} />
      </LineChart>
    </ResponsiveContainer>
    <MinMaxContainer>
      <MinContainer>
        <OddsValue style={{ fontSize: '16px' }}>{getMinMaxWithDate(betHistory)[0].odds.toFixed(2)}</OddsValue>
        <OddsTimestamp style={{ fontSize: '12px' }}>{getMinMaxWithDate(betHistory)[0].timestamp}</OddsTimestamp>
      </MinContainer>
      <MaxContainer>
        <OddsValue style={{ fontSize: '16px' }}>{getMinMaxWithDate(betHistory)[1].odds.toFixed(2)}</OddsValue>
        <OddsTimestamp style={{ fontSize: '12px' }}>{getMinMaxWithDate(betHistory)[1].timestamp}</OddsTimestamp>
      </MaxContainer>
    </MinMaxContainer>
    <HistoryContainer>
      {betHistory
        .map((odds, idx) =>
          <OddsContainer>
            <OddsTimestamp style={{ color: idx === 0 ? '#E9DF8C' : 'white', fontWeight: idx === 0 ? 'bold' : 'normal' }}>{new Date(odds.timestamp || odds.updatedOn).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</OddsTimestamp>
            <OddsValueContainer odds={odds} idx={idx} />
          </OddsContainer>
        )}
    </HistoryContainer>
  </>
)
}