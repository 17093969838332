import React from "react";
import Stepper from "react-stepper-horizontal";

export const Navigation = (props) => {
  return (
    <div className="navigation">
      <div className="help-text">
        Si has detectado un error en el arbitraje este formulario te permite enviarnos un informe que nos permitirá
        subsanar el fallo en futuro.
      </div>
      <div className="stepper">
        <Stepper
          steps={props.type === 'arb' ? [{ title: "Bookie" }, { title: "Problema" }, { title: "Descripción" }] : [{ title: "Problema" }, { title: "Descripción" }]}
          activeStep={props.currentStep - 1}
          defaultColor="rgb(58, 87, 60)"
          defaultOpacity={0.5}
          activeColor="#0E2123"
          activeTitleColor="#fff"
          completeColor="#EDA33B"
          completeTitleColor="white"
          circleFontColor="white"
          titleFontSize={14}
        />
      </div>
    </div>
  );
};
