// UserHomePage.js
import React, { useState, useEffect } from "react";

import { checkIfTokenStillValid } from "../../repositories/utils";
import { useUser } from "../../hooks";
import { useTranslation } from 'react-i18next';
import { NavBarMain } from "..";

// The component itself
export function Invoices({ history }) {
  const {
    getInvoices,
    getInvoice
  } = useUser();
  const [invoices, setInvoices] = useState([]);
  const { t } = useTranslation();


  useEffect(() => {
    async function fetchData() {
      checkIfTokenStillValid(history);
      const invoices = await getInvoices();
      setInvoices(invoices);
    }
    fetchData();
  }, [history, getInvoices]);

  const handleDownload = async (invoice) => {
    const response = await getInvoice(invoice.id);
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `invoice_${invoice.id}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#111E12] p-3">
      <NavBarMain currentPage="home" history={history}/>
      <div className="flex flex-col max-w-3xl w-full mx-auto mt-12">
        <h1 className="text-2xl font-bold text-white mb-6">{t('invoices')}</h1>
        {invoices.length > 0 ? (
          <ul className="bg-[#1E2D1F] rounded-lg shadow-lg overflow-hidden">
            {invoices
              .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
              .map((invoice) => (
                <li key={invoice.id} className="border-b border-[#2A3C2B] last:border-b-0">
                  <div className="flex items-center justify-between p-4">
                    <span className="text-sm text-gray-400">
                      {new Date(invoice.timestamp).toLocaleDateString()}
                    </span>
                    <span className="text-base font-semibold text-white">
                      {invoice.amount}€
                    </span>
                    <button
                      onClick={() => handleDownload(invoice)}
                      className="bg-[#E9FD8C] hover:bg-[#D1E57E] text-[#111E12] text-sm py-2 px-4 rounded transition duration-150 ease-in-out"
                    >
                      <i className="fas fa-download mr-2"/> {t('download')}
                    </button>
                  </div>
                </li>
              ))}
          </ul>
        ) : (
          <p className="text-center text-gray-400 text-base mt-5">{t('noInvoices')}</p>
        )}
      </div>
    </div>
  );
}
